import { ThemeProvider, StyleReset, Div, Container, Row, Col, Text, Button, Anchor } from "atomize"
import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"
import Footer from "../components/footer"
import "../settings/index.css"
import Header from "../components/header"
import SEO from "../components/seo"
import CoverWave from "../images/assets/w-come-funziona.svg"
import SubheaderText from "../components/SubheaderText"
import BoldText from "../components/BoldText"
import HeadingText from "../components/HeadingText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox, faBreadSlice, faExclamation, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

let config = require("../settings/config")

const theme = config.theme
const url = config.url

const MemberEntry = props => {
    return (
        <>
            <Row p={{ t: { xs: "3rem", lg: "4rem" } }}>
                <Col size={{ xs: "12", lg: "5" }} textAlign="center" pos="relative">
                    {props.img}
                </Col>
                <Col size={{ xs: "12", lg: "7" }} style={{ margin: "auto" }}>
                    <Text textColor="gunmetal" textSize="display1" textWeight="bold" textAlign="left">
                        {props.name}
                    </Text>
                    <Text textColor="accent" textSize="display1" textAlign="left">
                        {props.role}
                    </Text>
                    <Text textColor="gunmetal" m={{ t: "2rem" }} textSize="subheader" textAlign="left">
                        {props.description}
                    </Text>
                </Col>
            </Row>
        </>
    )
}

const How = () => {
    return (
        <PageTransition>
            <ThemeProvider theme={theme}>
                <StyleReset />

                <Header />
                <SEO
                    title="Scopri come possiamo ridurre gli sprechi!"
                    description="Squiseat si impegna a ridurre lo spreco alimentare. Ognuno di noi può fare la differenza: scopri come evitare gli sprechi di cibo. Scarica la nostra app!"
                    pathname="obiettivi"
                />
                <Div tag="main" style={{ position: "relative", overflow: "hidden" }}>
                    <Div
                        tag="section"
                        pos="relative"
                        bg="accent_ulight"
                        zIndex="1"
                        p={{
                            t: {
                                xs: "1rem",
                                sm: "2rem",
                                lg: "8rem",
                                xl: "10rem",
                            },
                        }}
                    >
                        <Container>
                            <Text
                                textSize={{
                                    xs: "display2",
                                    md: "display3",
                                }}
                                textWeight="bold"
                                textAlign={{ lg: "center" }}
                                textColor="gunmetal"
                                m={{ t: { xs: "1rem", lg: "0.5rem" }, b: { xs: "2rem", lg: "0" } }}
                            >
                                Chi trova un <BoldText textColor="primary">avanzo</BoldText> trova un{" "}
                                <BoldText textColor="primary">tesoro</BoldText>
                            </Text>
                            <SubheaderText
                                textAlign={{ lg: "center" }}
                                m={{ t: { md: "3rem" }, y: { xs: "1rem", md: "0" } }}
                            >
                                Buttare il cibo <BoldText>non piace a nessuno</BoldText>. Ecco perché nasce{" "}
                                <BoldText textColor="primary">Squiseat</BoldText>!
                            </SubheaderText>
                        </Container>
                    </Div>

                    <Div tag="section">
                        <Div m={{ t: { xs: "-1rem", lg: "-7rem" } }}>
                            <CoverWave className="wave" />
                        </Div>
                        <Container pos="relative" zIndex="1">
                            <Row m={{ y: { xs: "1.5rem", md: "0" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        Allo spreco <BoldText textColor="primary">diamo pan per focaccia</BoldText>.
                                    </HeadingText>
                                    <SubheaderText>
                                        Ogni anno <BoldText>si spreca 1/3 del cibo prodotto</BoldText> nel mondo.
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        <BoldText textColor="primary">Follia no?</BoldText> Ma invece che ignorare il
                                        problema e passare la patata fritta (no forse era bollente), abbiamo detto{" "}
                                        <BoldText>"dai rega facciamo un'app e risolviamo il problema va là"</BoldText>{" "}
                                        ed eccoci qua!
                                    </SubheaderText>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div
                                        m={{ t: { xs: "-0.5rem", md: "0" }, l: { xs: "20vw", md: "2vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                        transform="rotate(45deg)"
                                    >
                                        <Div pos="absolute">
                                            <FontAwesomeIcon
                                                icon={faBreadSlice}
                                                size="9x"
                                                color={theme.colors.gunmetal}
                                                className="logo"
                                            />
                                        </Div>
                                        <Div pos="absolute" left="1rem" top="-0.5rem">
                                            <FontAwesomeIcon
                                                icon={faBreadSlice}
                                                size="9x"
                                                color={theme.colors.primary}
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section" m={{ y: "2rem" }}>
                        <Container>
                            <Row m={{ y: { xs: "3rem", md: "5rem" } }} align="center">
                                <Col
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    size={{ xs: "12", md: "5" }}
                                    textAlign="right"
                                    order={{ xs: 2, md: 1 }}
                                >
                                    <Div
                                        m={{ t: { xs: "0.5rem", md: "3rem" }, l: { xs: "52vw", md: "2vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                        transform={{ xs: "rotate(45deg)", md: "rotate(15deg)" }}
                                    >
                                        <Div pos="absolute">
                                            <FontAwesomeIcon
                                                icon={faExclamation}
                                                size="10x"
                                                color={theme.colors.gunmetal}
                                                className="logo"
                                            />
                                        </Div>
                                        <Div pos="absolute" left="1rem" top="-0.5rem">
                                            <FontAwesomeIcon
                                                icon={faExclamation}
                                                size="10x"
                                                color={theme.colors.primary}
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                                <Col size={{ xs: "12", md: "7" }} order={{ xs: 1, md: 2 }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        Eccedenze? <BoldText textColor="primary">Eccellenze</BoldText>!
                                    </HeadingText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        "Venghino signori venghino!", ok basta tono da bancarella, quello che vogliamo
                                        dire è che{" "}
                                        <BoldText style={{ whiteSpace: "nowrap" }} textColor="primary">
                                            avanzi = roba buona
                                        </BoldText>
                                        .
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "1rem", md: "2rem" } }}>
                                        Il nostro compito è rendere chiara questa caratteristica unendola alla{" "}
                                        <BoldText>trasparenza</BoldText>, per questo nella nostra app vedete le foto dei
                                        prodotti!
                                    </SubheaderText>
                                </Col>
                            </Row>
                        </Container>
                    </Div>

                    <Div tag="section">
                        <Container pos="relative" zIndex="1">
                            <Row m={{ y: { xs: "3rem", md: "5rem" } }} align="center">
                                <Col size={{ xs: "12", md: "7" }} style={{ padding: "0" }}>
                                    <HeadingText p={{ y: "0.5rem" }}>
                                        Non ci piace giocare a <BoldText textColor="primary">mosca cieca</BoldText>.
                                    </HeadingText>
                                    <SubheaderText>
                                        Ci piace <BoldText>scegliere cosa mangiare</BoldText> e quanto mangiarne,{" "}
                                        <BoldText>senza andare a tentoni</BoldText>. Insomma, si mangia quello di cui si
                                        ha voglia!
                                    </SubheaderText>
                                    <SubheaderText p={{ t: { xs: "0.5rem", md: "1.5rem" } }}>
                                        Questo va anche a <BoldText>vantaggio dei locali</BoldText>, così vengono{" "}
                                        <BoldText>valorizzati</BoldText> i loro prodotti, senza essere alla rinfusa in
                                        una busta.
                                    </SubheaderText>
                                </Col>
                                <Col
                                    size={{ xs: "12", md: "5" }}
                                    textSize={{ xs: "heading", md: "display2", lg: "display3" }}
                                    textAlign="right"
                                >
                                    <Div
                                        m={{ l: { xs: "25vw", md: "2vw" } }}
                                        pos="relative"
                                        zIndex="2"
                                        transform="rotate(35deg)"
                                    >
                                        <Div pos="absolute">
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                size="8x"
                                                color={theme.colors.gunmetal}
                                                className="logo"
                                            />
                                        </Div>
                                        <Div pos="absolute" left="1rem" top="-0.5rem">
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                                size="8x"
                                                color={theme.colors.primary}
                                                className="logo"
                                            />
                                        </Div>
                                        <FontAwesomeIcon icon={faExclamation} size="10x" color="transparent" />
                                    </Div>
                                </Col>
                            </Row>
                            <HeadingText textAlign={{ lg: "center" }}>
                                Convinto? <BoldText textColor="primary">SCARICA L'APP!</BoldText>
                            </HeadingText>
                            <Div m={{ t: "2rem", x: " 0" }}>
                                <SubheaderText textAlign="center">
                                    Se invece non lo sei, <BoldText>scaricala comunque</BoldText> e dicci cosa non ti
                                    piace!
                                </SubheaderText>

                                <Div d={{ md: "flex" }} justify="center">
                                    <Anchor
                                        href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteIOS"
                                        id="download_ios_come_funziona"
                                        target="_blank"
                                        title="Scarica per iPhone | Squiseat"
                                    >
                                        <Div
                                            w={{
                                                xs: "12.5rem",
                                                md: "15rem",
                                            }}
                                            m="1rem auto"
                                            p={{ x: "0.5rem" }}
                                        >
                                            <StaticImage
                                                src="../images/pulsante-download-app-store-ios.png"
                                                alt="Pulsante per il download su App Store iOS"
                                                placeholder="blurred"
                                            />
                                        </Div>
                                    </Anchor>

                                    <Anchor
                                        href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteAndroid"
                                        id="download_android_come_funziona"
                                        target="_blank"
                                        title="Scarica per smartphone Android | Squiseat"
                                    >
                                        <Div
                                            w={{
                                                xs: "12.5rem",
                                                md: "15rem",
                                            }}
                                            m="1rem auto"
                                            p={{ x: "0.5rem" }}
                                        >
                                            <StaticImage
                                                src="../images/pulsante-download-play-store-android.png"
                                                alt="Pulsante per il download su Play Store Android"
                                                placeholder="blurred"
                                            />
                                        </Div>
                                    </Anchor>
                                </Div>
                            </Div>
                        </Container>
                    </Div>
                </Div>
                <Footer />
            </ThemeProvider>
        </PageTransition>
    )
}

export default How
